<template>
  <div class="container">
    <table class="storeList">
      <tr>
        <th>门店列表</th>
        <th>门店地址</th>
      </tr>
      <tr v-for="(item, index) in storeList" :key="index">
        <td>{{ item.orgName }}</td>
        <td>{{ item.orgAddress }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import config from '@/config'
export default {
  name: 'otherStores',
  components: {},
  data() {
    return {
      storeList: config.storeList
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.storeList {
  width: 950px;
  margin: 0 auto;
  padding-top: 30px;
  line-height: 26px;
  tr {
    th {
      font-size: 18px;
      padding: 15px 0;
      text-align: left;
      width: 50%;
    }
  }
}
</style>
